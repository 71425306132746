import React, { useState, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router';



const Sydney = [
  { label: 'Name', value: 'Sydney Tu' },
  { label: 'WiNternship Program', value: '2023' },
  { label: 'Title', value: 'Junior at UT Austin' },
]

const Maria = [
  { label: 'Name', value: 'Maria Rangel' },
  { label: 'WiNternship Program', value: '2023' },
  { label: 'Title', value: 'Senior at Trinity University in San Antonio' },
]

const Kath = [
  { label: 'Name', value: 'Katherine Vasquez' },
  { label: 'WiNternship Program', value: '2020' },
  { label: 'Title', value: 'Empowering Journeys: Navigating Neuroscience, Mentorship, and Resilience with Women in Neuroscience' },
]

// Define your tab content components here
function TabOneContent() {
  // You will fill in the details for each tab's content
  return <div>
 
      <div className="bg-white py-6 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4 sm:sticky sm:top-32 self-start z-10">
              <div className="relative overflow-hidden rounded-3xl bg-gray-900 shadow-2xl lg:max-w-lg">
                <StaticImage
                  src="../images/SydneyTu.jpg"
                  alt="Sydney Tu"
                  />
              </div>
            </div>
          <div>
          <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
            <dl className="grid flex gap-8 border-b border-gray-900/10 py-2">
              {Sydney.map((stat, statIdx) => (
                <div key={statIdx}>
                  <dt className="text-sm font-semibold text-gray-600">{stat.label}</dt>
                  <dd className="mt-2 text-large font-bold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
              <div className="max-w-xl">
                <p className="mt-6">
                When I shared the news of my acceptance into the University of Texas at Austin (UT) with my parents, their elation was palpable. Their excitement only heightened when I presented a YouTube video, which showcased the prestigious reputation of UT, offering a glimpse into the remarkable journey that awaited me the following fall.
                </p>
                <p className="mt-8">
                While they were proud of me for pursuing my dream of going to medical school, they were unfamiliar with the processes of selecting the right classes or guiding me toward resources that would help keep me on track for medical school. As a first-generation college student, I found it difficult to navigate the complexities of higher education.
                </p>
                <p className="mt-8">
                When I first heard about Women in Neuroscience’s (WiN) program from my freshman year tutor, it sounded exactly like the support and mentorship that I needed. WiN’s program offers young women an 8-week paid internship, complete with lab research experience, mentorship and a speaker series from neuroscientists across the country. 
                </p>
                <p className="mt-8">
                As a WiNtern, I was paired with a UT faculty member, Dr. Caitlin Orsini, in the Psychology and Neuroscience Department. I spent an entire summer working in Dr. Orsini's lab, contributing to ongoing research. My project focused on motivation and the role of dopamine, a neurotransmitter, in influencing motivation. While the work itself was indeed fascinating, I noticed something distinct in this lab compared to my previous experiences in others. For the first time, my lab consisted of all women. 
                </p>
                <p className="mt-8">
                In the past, I’ve noticed many of the people surrounding me in research labs in this and other STEM fields have been white men. The underrepresentation of women and people of color in scientific fields remains a significant challenge. While I haven't faced much discrimination personally, I've seen some individuals encounter biased comments and discrimination in their academic and professional journeys. This can be extremely discouraging to stay in the field. 
                </p>
                <p className="mt-8">
                The presence of other resilient women in this lab, who had encountered similar obstacles in their quest for success, served as a powerful testament that progress was being made. Programs like WiN play a pivotal role in breaking down barriers and fostering opportunities for aspiring women in neuroscience. This shift towards greater representation is instrumental in expanding the diverse perspectives and life experiences brought to the decision-making table, ultimately enhancing the potential for innovative, holistic approaches in addressing neurodegenerative diseases and neurological disorders.
                </p>
                <p className="mt-8">
                Through the unwavering support and guidance of WiN and the lessons learned on my personal journey, I was inspired to pay it forward. Today, I proudly serve as a mentor in my Scholars Program at UT Austin, assisting incoming first-year students in navigating the challenging transition from high school to college. My mission is to empower and support underrepresented STEM students, ensuring they can overcome obstacles and confidently pursue their dreams. In doing so, I hope to contribute to a future where diversity and inclusion flourish in the scientific community, paving the way for groundbreaking discoveries and novel solutions to some of the most pressing neurological challenges of our time.
                </p>
              </div>
            </div>
            <div className="mt-10 flex">
              <a href="/join-us/wintern-apply" className="text-base font-semibold leading-7 text-indigo-600 hover:underline hover:underline-offset-4 hover:text-indigo-700">
                Become a WiNtern <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </div>
}


function TabTwoContent() {
  // You will fill in the details for each tab's content
  return <div>
 
      <div className="bg-white py-6 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4 sm:sticky sm:top-32 self-start z-10">
              <div className="relative overflow-hidden rounded-3xl bg-gray-900 shadow-2xl lg:max-w-lg">
                <StaticImage
                  src="../images/MariaRangel.jpg"
                  alt="Maria Rangel"
                />
              </div>
            </div>
            <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
            <dl className="grid flex gap-8 border-b border-gray-900/10 py-2">
              {Maria.map((stat, statIdx) => (
                <div key={statIdx}>
                  <dt className="text-sm font-semibold text-gray-600">{stat.label}</dt>
                  <dd className="mt-2 text-large font-bold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
              <div className="max-w-xl">
                <p className="mt-6">
                From a young age, I knew I wanted to go to med school. What I didn’t realize at the time was how complicated it would be to get there, especially as a first-generation college student. Luckily, I was introduced to Breakthrough Central Texas, a nonprofit committed to helping students forge their own paths to earn their college degrees. Starting in the 6th grade, Breakthrough helps first-generation college students overcome systemic barriers and find college success. The Austin-based nonprofit assisted in making sure I had everything I needed as I applied for college and made me feel confident heading off to campus when I was accepted into Trinity University in San Antonio. 
                </p>
                <p className="mt-8">
                Even during my freshman year, the support never waned. My mentor, David, went above and beyond, pointing me towards a special opportunity. He suggested a program, the Women in Neuroscience (WiN), made to help women in neuroscience. Urging me to apply, he highlighted its benefits: a fully paid summer internship with 8 weeks in a lab and ongoing mentorship throughout my career. David’s guidance and continual support made my journey into higher education smoother and more confident.
                </p>
                <p className="mt-8">
                Once I learned more about the program, or ‘WiNternship,’ I realized how crucial the experience could be for my college career. I had seen more privileged peers secure lab internships to enhance their resumes, but I lacked the same connections and means to gain research experience beyond the classroom. This seemed like the perfect opportunity, so I applied immediately. 
                </p>
                <p className="mt-8">
                I was extremely excited when I found out I was accepted! But the intimidation came flooding in when I saw who I would be paired with. Dr. Michela Marinelli is such a smart, amazing professor and researcher that I wasn’t sure my lack of experience would live up to her expectations. But, when I stepped into the lab for the first time, she and everyone else in the room immediately quashed any doubts I had about myself. 
                </p>
                <p className="mt-8">
                They were extremely welcoming, answering any questions, addressing all concerns and assuring me that I would be supported throughout the course. I was expecting to be more of a fly-on-the-wall in the lab, but I ended up getting hands-on experience that tested my confidence. 
                </p>
                <p className="mt-8">
                During my first two weeks, I mainly focused on training and familiarizing myself with the lab's operations, including testing rodents. Initially, I was extremely apprehensive about getting close to the adult rats. I had a speech prepared in my head that outlined why I didn’t think I could handle the rodents on my own. However, with the guidance of one of the lab managers, who stayed by our side and provided step-by-step instructions, I finally felt comfortable enough to give it a try. Over the next several weeks, I continued to build my confidence and eventually started conducting experiments independently, which was a remarkable experience. 
                </p>
                <p className="mt-8">
                While that interactive lab experience was fantastic, the support WiN provides is truly unmatched. This journey taught me that with the right opportunities and steadfast encouragement, I can conquer self-doubt and achieve extraordinary accomplishments. Programs like Breakthrough and WiN give first-generation students like me the confidence we need to pursue a career we’re passionate about, making the impossible seem possible. I hope that my two little sisters, with whatever career path they choose to pursue, experience this same level of support. 
                </p>
              </div>
            </div>
            <div className="mt-10 flex">
              <a href="/join-us/wintern-apply" className="text-base font-semibold leading-7 text-indigo-600 hover:underline hover:underline-offset-4 hover:text-indigo-700">
                Become a WiNtern <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
}



function TabThreeContent() {
  // You will fill in the details for each tab's content
  return <div>
 
      <div className="bg-white py-6 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4 sm:sticky sm:top-32 self-start z-10">
              <div className="relative overflow-hidden rounded-3xl bg-gray-900 shadow-2xl lg:max-w-lg">
                <StaticImage
                  src="../images/KatherineVasquez.jpg"
                  alt="Katherine Vasquez"
                />
              </div>
            </div>
            <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
            <dl className="grid flex gap-8 border-b border-gray-900/10 py-2">
              {Kath.map((stat, statIdx) => (
                <div key={statIdx}>
                  <dt className="text-sm font-semibold text-gray-600">{stat.label}</dt>
                  <dd className="mt-2 text-large font-bold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
              <div className="max-w-xl">
                <p className="mt-6">
                In 2020, amidst the chaos of the pandemic, I found myself at a crossroads. I had recently transferred to the University of Texas at Austin (UT) from a community college in East Texas, pursuing a degree in Neuroscience. While I was passionate about the field, catching up on research experience posed a challenge. Such opportunities aren’t readily available in community colleges, so I didn’t even know where to start. That's when I received an email that changed my life.
                </p>
                <p className="mt-8">
                The email introduced me to Women in Neuroscience (WiN)’s internship program, aptly nicknamed ‘WiNternship,’ which sponsors young women for paid 8-week internships with scientists in the UT Department of Neuroscience. The best part? The program didn't require prior neuroscience research experience. I felt an immediate sense of relief and applied right away. It was precisely what I needed, and I was ecstatic when I found out I was accepted that summer.
                </p>
                <p className="mt-8">
                Due to the pandemic, the research aspect of the program shifted online instead of in-person lab work. To compensate, WiN’s founder, Judi Nudelman, arranged virtual sessions with some of the best neuroscientists in the country throughout the summer. Hearing about their successes was enlightening; they openly shared the hardships they faced. They emphasized that nobody's career or education path is linear, and it's okay to explore and change directions.
                </p>
                <p className="mt-8">
                Working with Dr. Nico Osier was a highlight of my summer. Like me, Dr. Osier was also a first-generation college student. Our one-on-one conversations were invaluable, providing guidance and support beyond research. Dr. Osier exemplified the importance of mentorship and showed me it was possible to succeed in a field that often felt daunting.
                </p>
                <p className="mt-8">
                When these scientists shared their challenges and the winding routes to success, it dispelled the illusion that there's only one right way to achieve goals. It's liberating to know that setbacks and detours are part of the journey, and resilience can overcome them. This realization has not only bolstered my self-belief but also reinforced my commitment to supporting and inspiring the next generation of young Latina scientists. 
                </p>
                <p className="mt-8">
                Today, I find myself on the East Coast, enrolled in Boston University’s NIH Postbaccalaureate Research Education Program (PREP), applying to PhD programs in Cancer Biology and Immunology. My path has taken unexpected turns, and I’m grateful for every one of them. The support I received from Women in Neuroscience has been a constant guiding force.
                </p>
                <p className="mt-8">
                While everyone’s stories are unique, I understand what many Hispanic first-generation college students feel in science — not knowing whether it’s our home or what steps we need to take to reach our goals. However, I’ve learned that even in moments of feeling lost, I’ve always had a community willing to support me. WiN bridges the gap, providing both scientific training and a nurturing community.
                </p>
                <p className="mt-8">
                I hope as many young women as possible can gain the same support from the WiNternship for decades to come. I can’t wait to see the amazing things they achieve. It's not just about learning neuroscience; it's about gaining lifelong mentorship, building a supportive community, and discovering the confidence to pursue dreams, no matter where the journey takes you.
                </p>
              </div>
            </div>
            <div className="mt-10 flex">
              <a href="/join-us/wintern-apply" className="text-base font-semibold leading-7 text-indigo-600 hover:underline hover:underline-offset-4 hover:text-indigo-700">
                Become a WiNtern <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
}

// More tab content components as needed...

const tabs = [
  { name: 'Sydney Tu', component: TabOneContent },
  { name: 'Maria Rangel', component: TabTwoContent },
  { name: 'Katherine Vasquez', component: TabThreeContent },
  // Add more tabs as needed
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TabsComponent() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeTabName = params.get('tab'); // Assuming the URL parameter is named 'tab'
    const foundIndex = tabs.findIndex(tab => tab.name === activeTabName);
    if (foundIndex !== -1) {
      setActiveTabIndex(foundIndex);
    }
  }, [location]);

  const ActiveTabContent = tabs[activeTabIndex].component;

  return (
    <div>
      {/* Tab navigation with flexbox */}
      <div className="border-b border-gray-200">
        <nav className="flex justify-center -mb-px" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <button
              key={tab.name}
              className={classNames(
                index === activeTabIndex
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700',
                'w-1/4 border-b-2 py-4 px-1 text-center sm:text-large text-small font-medium'
              )}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Active tab content */}
      <div>
        <ActiveTabContent />
      </div>
    </div>
  );
}
