import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Subscribe from "../../components/subscribe"
import { StaticImage } from "gatsby-plugin-image"
import TabsComponent from "../../components/studentStories"

const impactstoriesPage = () => (
    <Layout siteTitle={"Impact Stories"}> 
      <Seo title="Impact Stories" />

      <div className="relative isolate overflow-hidden bg-fixed border-b-4 border-indigo-700 bg-gray-900">
  <StaticImage
    src="../../images/ImpactStoriesBanner.jpg"
    alt=""
    className="!absolute inset-0 -z-10 h-full w-full object-cover"
  />
  <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
    <svg
      className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
      viewBox="0 0 1155 678"
    >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".2"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-indigo-700 sm:text-6xl">
              Impact Stories
            </h1>
            <p className="mt-6 text-lg font-light leading-8 text-white">
            We strive to give our interns truly transformative experiences, both in terms of acquired technical skills and professional development. Alongside some of the brightest minds in the neuroscience field, each participant contributes to their projects in meaningful ways and obtains an invaluable understanding of what life is like as a practicing researcher. But don't just take our word for it! See how our former WiNterns enjoyed the process.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/join-us/wintern-apply"
                className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Apply Now
              </a>
              <a href="#stories-section" className="text-base font-semibold leading-7 text-white hover:underline hover:underline-offset-4 hover:text-indigo-700">
               Read the stories <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".2"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>

    <section id="stories-section" className="bg-gradient-to-b from-white to-white pt-4 pb-12 md:py-20 lg:py-16">
      
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">

      <div className="relative mx-auto max-w-7xl px-6 pb-16 lg:px-8">
        <h2 className="text-3xl mx-auto font-bold tracking-tight text-indigo-600 text-center">
          Breaking Barriers, Building Futures
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 text-center">
        Read the inspiring stories of how our WiNterns shattered barriers and unleashed their full potential as scientists.
        </p>
      </div>

      <TabsComponent />
        
        <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          <blockquote className="mt-10">
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
              <p>
                &ldquo;As I had very little experience in a lab setting, the beginning of the internship was difficult for me, but it was an exciting time to be exposed to all types of lab techniques. My teammates and I got to observe brain slicing experiments, surgeries on the brains of mice, and even got to learn how to handle mice. 
                <br /><br />
                I am extremely grateful for being able to observe amazing experiments like these that contribute to research and lead to ground-breaking discoveries. It made me feel more motivated to pursue a path in research.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="sm:flex sm:items-center sm:justify-center sm:flex-row flex flex-col flex-wrap align content-center items-center justify-center">
                <div className="md:flex-shrink-0">
                  <StaticImage
                    className="mx-auto h-10 w-10 rounded-full"
                    src="../../images/InternMarissa.jpg"
                    alt="Intern Marissa"

                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">Marissa</div>

                  <svg className="mx-1 hidden h-5 w-5 text-indigo-600 md:block" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">2021 WiNtern, The University of Texas at Austin</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>

    <div className="bg-white pt-16 lg:py-24">
      <div className="bg-indigo-600 pb-16 lg:relative lg:z-10 lg:pb-0">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
          <div className="relative lg:-my-8">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
              <div className="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <StaticImage
                  className="object-cover lg:h-full lg:w-full"
                  src="../../images/InternTieranni.jpg"
                  alt="Intern Tieranni"
                  objectPosition={"top"}
                />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
            <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-2xl font-medium text-white">
                  One of my favorite things about being a WiNtern was that no two days were exactly the same. Dr. Henry made sure that I was exposed to new projects, information, and experiences throughout the summer. I had access and opportunity to meet and interact with many people in the lab. I love that I was given both structure and the freedom to branch out into the areas that interested me. 
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">Tieranni</p>
                  <p className="text-base font-medium text-indigo-100">2021 WiNtern, Huston-Tillotson University</p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>


    <section className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-6 pt-20 pb-12 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
        </svg>

        <svg
          className="absolute right-full top-1/2 hidden translate-x-1/2 -translate-y-1/2 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0">
            <img
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>

          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-indigo-200 opacity-50"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl font-medium leading-9 text-gray-900">
                <p>
                Conducting neuroscience research with WiN allowed me to build up the scientific analysis skills and experience I needed to be accepted into medical school and to continue doing research at a graduate student level. 
                <br /><br />
                In 2021, I finished my pre-clinical year of med school and completed my pediatrics, ob/gyn and surgery rotations in the middle of the pandemic. Along with this, I conducted research in ophthalmology and am hoping to get some papers published soon. I'll be finishing up my rotational year this year and hope to do an MPH next year. 
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <StaticImage
                      className="h-12 w-12 rounded-full"
                      src="../../images/InternSaima.jpg"
                      alt=""
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">Saima</div>
                    <div className="text-base font-medium text-indigo-600">2018 WiNtern, The University of Texas at Austin</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div className="relative mb-10 bg-indigo-800/30 backdrop-brightness-75 bg-no-repeat bg-cover bg-center">
    <StaticImage
      src="../../images/OurProgamBanner4.jpg"
      alt="Background image"
      className="!absolute inset-0 z-[-1]"
      layout="constrained"
    />
      <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-white">
            Interested in becoming a WiNtern 
            <br />
            or serving as a faculty host?
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-700">
            If you'd like to participate, we'd love to hear from you. To get started, please reach out using one of the paths below.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/join-us/wintern-apply"
                className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Become a WiNtern
              </a>
              <a href="/join-us/mentor/" className="text-base font-semibold leading-7 text-white hover:underline hover:underline-offset-4 hover:text-indigo-700">
              Become a Mentor <span aria-hidden="true">→</span>
              </a>
          </div>
        </div>
      </div>
    </div>

    {/* email subscribe */}
    <Subscribe />

    </Layout>
)

export const Head = () => <Seo title="Impact Stories" />

export default impactstoriesPage